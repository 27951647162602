<template>
    <div class="time__input" v-bind:class="{
        disabled: disabled,
        focus: isFocused || field.isError || isDanger,
        error: field.isError || isDanger,
    }">
        <input type="text" v-model="currentTime" inputmode="numeric" 
        maxlength = "5"
        :disabled="disabled"
        :placeholder="placeholder"
        v-click-outside="validate"
        @focus="isFocused = true" 
        @blur="isFocused = false"
        v-bind:class="{
            error : field.isError || isDanger,
        }">
    </div>
</template>

<script>
import { HHMMToMinutes, dateIsPast, convertTZ } from '../../scripts/date';
import Field from '../../scripts/field';
import Form from '../../scripts/form'

export default {
    name: 'TimeInput',
    directives: {
      'click-outside': {
          bind: function (el, binding, vnode) {
            el.clickOutsideEvent = function (event) {
              // here I check that click was outside the el and his children
              if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
              }
            };
            document.body.addEventListener('click', el.clickOutsideEvent)
          },
          unbind: function (el) {
            document.body.removeEventListener('click', el.clickOutsideEvent)
          },
        }
    },
    model: {
        prop: 'time',
        event: 'update:time'
    },
    props:{
        time: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "HH:MM",
        },
        form: {
            type: Form,
        },
        name: {
            type: String,
        },
        enableMidnight: {
            type: Boolean,
            default: false,
        },
		enableCheckPastTime: {
			type: Boolean,
			default: true,
		},
        isDanger: {
            type: Boolean,
        },
        max: Number,
        min: Number,
    },

    created(){
        if (this.form) {
            this.form.addField(this.field)
            this.$watch(`form.duration`, 
                () => this.validate(), 
                {deep:true}
            )
            this.$watch(`form.dateString`, 
                () => this.validate(), 
                {deep:true}
            )
        }
        this.field.validator = this.validate
    },

    data() {
        return {
            isFocused: false,
            field: new Field(this.name ?? "", "Field value is not valid"),
        };
    },

    computed: {
        currentTime: {
            get() {
                return this.time
            },
            set(newValue) {
                this.field.isError = false
                
                let isEnterMode = newValue.length > this.currentTime.length
                let helpedNewValue = this.timeHelper(newValue, isEnterMode)
                
                this.$emit('update:time', helpedNewValue)

                if (newValue.length != 5) 
                    return
                
                this.validate(newValue)
            }
        },
    },

    methods: {
        validate(value){
            if (this.disabled)
                return
            if (typeof value == "undefined" || typeof value == "object")
                value = this.currentTime
            console.log(`time validate ${value}`);
            if (value && value.length > 0){
                this.field.isError = !this.validateHhMm(value)
                if (this.field.isError)
                    return console.log('\tresult: not hh:mm time')
                
                if (value.length == 5 && (this.max || this.min))
                    this.field.isError = !this.checkLimits(value)
                if (this.field.isError)
                    return console.log('\tresult: not work time')

                if (value.length == 5 && this.form && this.form.date) {
                    this.field.isError = this.checkPastDate(value)
                }
                if (this.field.isError)
                   return console.log('\tresult: time is past')
                console.log("\tresult: OK")
            }
        },
        checkPastDate(value){
			if (!this.enableCheckPastTime)
				return false
            let date = new Date(this.form.date.getTime())
            let timeParts = value.split(":")
            date.setHours(Number(timeParts[0]), Number(timeParts[1]))

            let browserTimezone = this.$store.getters.browserTimezone
            let slotTimezone = this.form.timezone
            date = convertTZ(date, slotTimezone, browserTimezone)

            return dateIsPast(date)
        },
        checkLimits(value){
            let currentTimeMinutes = HHMMToMinutes(value)
            let check = true
            if (this.max) {
                check = check && currentTimeMinutes <= this.max
            }
            if (this.min) {
                check = check && currentTimeMinutes >= this.min
            }
            if (this.form && this.form.duration) {
                let dur = this.form.duration
                if (this.max) {
                    check = check && currentTimeMinutes + dur <= this.max
                }
                if (this.min) {
                    check = check && currentTimeMinutes + dur >= this.min
                }   
            }

            return check
        },

        timeHelper(newValue, isEnterMode){
            let time = newValue.replace( /[,. -]/,"0")
            if (!isEnterMode) return time
            
            let lastChar = newValue[newValue.length - 1]
            let oldFieldValue = this.currentTime

            switch(newValue.length){
                case 1:
                    if ([3,4,5,6,7,8,9].includes(Number(lastChar))){
                        time = "0" + lastChar + ":"
                    }
                    break;
                case 2:
                    if (lastChar == ":"){
                        time = "0" + oldFieldValue + ":"
                        break
                    }
                    if (this.enableMidnight && newValue[0] == '2' && Number(lastChar) == 4) {
                        time = '24:00'
                        break
                    }

                    if (newValue[0] == '2' && Number(lastChar) > 3) {
                        time = '23'
                    }
                    
                    time += ":"
                    break
                case 4:
                    if ([6,7,8,9].includes(Number(lastChar))){
                        time = oldFieldValue + "0" + lastChar
                    }   
                    break
            }

            return time
        },

        validateHhMm(value) {
            if (this.enableMidnight) return /^(?:[01]\d|2[0-3]):[0-5]\d|24:00$/.test(value)

            return /^(2[0-3]|[0-1]?[\d]):[0-5][\d]$/.test(value);
        }
    },
};
</script>

<style scoped>

.time__input{
    border: 1px solid;
    background-color: white;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    display: inline-block;
}

.time__input.disabled {
    pointer-events: none;
    background: #cacaca;
    color: grey;
}

.time__input.focus{
    border-color: hwb(212 0% 0%);
    box-shadow: 0px 0 0 2px #cbe3ff;
}

input {
    width: 5ch;
    font-size: 16px; 
    padding: 7px 11px;
    height: 24px;
    border: none;
    border-radius: 4px;
	box-sizing: content-box;
}

input::placeholder {
	font-size: 12px;
}

input[type="text"]:disabled {
    background: #e2e2e2;
    color: grey;
}

input:focus{
    outline: none;
    box-shadow: none;
}

.error{
    border-color: #f14668;
    color:  #f14668;
}

.error.focus{
    border-color: #f14668;
    box-shadow: 0px 0 0 2px rgba(241, 70, 104, 0.25);
}

input.disabled{
    background: #e9e9e9;
    color: grey;
}
</style>